import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { Button, Drawer, IconButton, Link, List, ListItem, ListItemText, styled, useMediaQuery, useTheme, Stack, Popover } from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Menu as MenuIcon, ExpandMore } from '@mui/icons-material';
import Logo from '../components/Logo/index';
// import { animateScroll as scroll } from 'react-scroll';


const LINKS = [
  // { name: 'HOME', link: '/' },
  { name: 'ABOUT', link: '/about' },
  { name: 'SERVICES', link: '/services' },
  { name: 'INSIGHTS', link: '/insights' },
  { name: 'CONTACTS', link: '/contacts' },
];



const ButtonContainer = styled(Button)(({ theme }) => ({
  height: '3rem',
  width: '10rem',
  objectFit: 'cover',
  marginTop: '1rem',
  marginLeft: '2rem',
  border: '3px solid #059ECC',
  borderRadius: '2rem',
  color: '#059ECC',
  textTransform: 'capitalize',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginLeft: '0rem',
  },
}));

export default function TopMenu() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [desktopSubMenuAnchorEl, setDesktopSubMenuAnchorEl] = React.useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState(null);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const renderSubMenu = (link, index) => (
    <List>
      {link.subMenu.map((subItem, subIndex) => (
        <ListItem button key={subIndex} onClick={() => navigate(subItem.link)}>
          <ListItemText primary={subItem.name} />
        </ListItem>
      ))}
    </List>
  );

  const renderSubMenuDesktop = (link, index) => (
    <Popover
      open={selectedMenuItem === index}
      anchorEl={desktopSubMenuAnchorEl}
      onClose={() => setSelectedMenuItem(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {renderSubMenu(link, index)}
    </Popover>
  );

  const handleDesktopSubMenuOpen = (event, index) => {
    setDesktopSubMenuAnchorEl(event.currentTarget);
    setSelectedMenuItem(index);
  };

  const handleSubMenuToggle = (event, index) => {
    event.stopPropagation();
    if (isSmallScreen) {
      setSelectedMenuItem(selectedMenuItem === index ? null : index);
    } else {
      handleDesktopSubMenuOpen(event, index);
    }
  };

  return (
    <>
      <Drawer anchor="top" open={drawerOpen} onClose={handleDrawerToggle}>
        <List>
          {LINKS.map((link, index) => (
            <Link key={index} component='a' href={link.link}>
              <ListItem button>
                <ListItemText primary={link.name} />
              </ListItem>
            </Link>
          ))}
          {/* <ListItem button onClick={() => navigate('/get-listed')}>
            <ListItemText primary="Get Listed" />
          </ListItem> */}
        </List>
      </Drawer>

      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" color="transparent"  elevation={0}>
          <Toolbar>
            <Box sx={{ pt: 2, width: '100%', margin: [0, 0, '0 3rem'] }} direction="row" alignItems="center" justifyContent={{ xs: 'space-between', md: 'space-between' }} gap={10}>
              <Stack direction='row' justifyContent='space-between' sx={{padding: '0 1rem'}} alignItems='center'>
                <Stack>
                  <Logo />
                </Stack>
                <Stack>
                  {!isSmallScreen && (
                    <Stack width="100%" gap={5} direction="row" alignItems="center" justifyContent="flex-end">
                      {LINKS.map((link, index) => (
                        <React.Fragment key={index}>
                          <Link
                            sx={{ color: 'white', whiteSpace: 'nowrap' }}
                            underline="none"
                            component={RouterLink}
                            to={link.link}
                            onClick={(e) => handleSubMenuToggle(e, index)}
                          >
                            {link.name}
                          </Link>
                          {link.subMenu && (
                            <React.Fragment>
                              <ExpandMore
                                sx={{ marginLeft: '0.2rem', cursor: 'pointer' }}
                                onClick={(e) => handleSubMenuToggle(e, index)}
                              />
                              {renderSubMenuDesktop(link, index)}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      ))}
                      
                    </Stack>
                  )}
                  {isSmallScreen && (
                    <IconButton onClick={handleDrawerToggle} size="small">
                      <MenuIcon color="inherit"sx={{color: 'white'}}/>
                    </IconButton>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
