import React from 'react'
import { Box, Stack, styled, Typography } from '@mui/material';
import { Colors } from '../theme/color';

const PrincipleImageContainer = styled('div')(({ theme }) => ({
    width: '90%',
    height: '58vh',
    overflow: 'hidden',
    border: '4px solid #FAEFE0',
    margin: '5rem 0',
    [theme.breakpoints.down('md')]: {
        margin: '1rem 1rem',
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        height: '45vh',
        justifyContent: 'center'
    }
  }));

  const BoxContainer = styled(Box)(({ theme }) => ({
    width: '90%',
    height: '25vh',
    [theme.breakpoints.down('md')]: {
        height: '20vh'
    }
  }));
  
  const DescriptionText = styled(Typography)(({ theme }) => ({
    fontSize: '1.2rem',
    color: '#FAEFE0',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  }));

  const HeadingText = styled(Typography)(({ theme }) => ({
    fontSize: '2rem',
    color: '#FAEFE0',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.5rem'
    }
  }));
  

function AboutCard({Heading, Description}) {
  return (
    <div>
        <PrincipleImageContainer>
           <Stack justifyContent='center'>
            {/* <Stack padding={{xs: 2, lg: 4}} height={{xs: 22, lg: 0}} paddingBottom={{xs: 2, lg: 10}} > */}
            <BoxContainer>
                <HeadingText variant="h5" color={Colors.lightOrange} paddingLeft={{xs: 3, lg: 3}} sx={{fontWeight: 'bold',  padding: '3rem 2rem', }}>{Heading}</HeadingText>
            </BoxContainer>
            {/* </Stack> */}
            <Stack sx={{borderTop: '4px solid white', padding: '3rem 2rem', }}>
                <DescriptionText variant="body1" color={Colors.lightOrange} >{Description}</DescriptionText>
            </Stack>
           </Stack>
        </PrincipleImageContainer>
    </div>
  )
}

export default AboutCard