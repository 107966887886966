import React from 'react'
import { styled, Button, Grid, Container, Box, Typography, } from '@mui/material';
import Card from '../components/Card';
import Service1 from '../assets/S1.jpg';
import Service2 from '../assets/S2.jpg';
import Service3 from '../assets/S3.jpg';
import Service4 from '../assets/S4.jpg';
import Service5 from '../assets/S5.jpg';
import Service6 from '../assets/S6.jpg';

const SERVICES = [
  {Heading: 'Soft Furnishings', image: Service1, Item: 'Pillows, blinds, curtains, carpets.', description: 'Carpet Well to well carpet and tiles. Blinds: Horizontal and vertical blinds, (wooden & Aluminum). Curtains: All types.'},
  {Heading: 'Furniture', image: Service2, Item: 'Home and Office furniture.', description: 'Classic, durable and beautiful furni-ture that suits your office or home.'},
  {Heading: 'Renovations', image: Service3, Item: 'We do Home and Office renovation.', description: 'Renovation involves demolition work, breaking down old structures & revitalizing your space, bringing a fresh look and renewed energy to your office/ home.'},
  {Heading: 'Flooring Works', image: Service4, Item: 'We do all types of floors', description: (<>Wooden floors <br />Bamboo floors <br />Tiles</>)},
  {Heading: 'Lighting', image: Service5, Item: 'Correct lighting & temperature', description: 'Florescent, incandescent, light emit-ting diode(LED), outdoor solar.'},
  {Heading: 'Office Partitioning', image: Service6, Item: 'We partition per your desired looks', description: (<>Alluminum/Glass <br />Gypsum <br />Wooden <br />Stonewall</>)},
]

const ActionButtonTwo = styled(Button)(({theme}) => ({
  border: '4px solid #D04C00',
  color: 'black',
  padding: '1rem',
  width: '20%',
  marginTop: '2rem',
  marginLeft: '1.5rem',
  borderRadius: '0',
  fontSize: '1.1rem',
  '&:hover': {
    backgroundColor: '#D04C00',
    color: 'white',
    // marginLeft: '0',
  },
  [theme.breakpoints.down('md')]: {
    width: '80%',
    fontSize: '1rem'
  }
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  marginBottom: '1rem',
  marginLeft: '2%',
  marginTop: '3rem',
  [theme.breakpoints.down('md')]: {
    marginTop: '2rem',
    marginBottom: '0rem'
  }
}));

function Services() {
  return (
    <div style={{background: '#F4F4F4', padding: '1rem 0'}}>
      <Container>
        <HeaderContainer>
          <Typography variant="h4" color="black" sx={{fontWeight: 'bold'}} marginTop={{xs: 1, lg: 1}}>Our Services</Typography>
        </HeaderContainer>
        <Grid container spacing={1} gap={0} marginTop={5} marginBottom={5}>
          {SERVICES.map((principle, index) => {
            return (
              <Grid item key={index} xs={12} md={6} lg={4}>
                  <Card Heading={principle.Heading} Item={principle.Item} Description={principle.description} imageUrl={principle.image} />
              </Grid>
            )
          })}
        <ActionButtonTwo>View All</ActionButtonTwo>
        </Grid>
      </Container>
    </div>
  )
}

export default Services