import React from 'react'
import Logo from '../assets/logo.png';
import { Box, Divider, Stack, styled, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import { ImFacebook } from "react-icons/im";
import { Colors } from '../theme/color';

const PrincipleImageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
  paddingTop: '5rem',
  background: Colors.orangeShade,
  [theme.breakpoints.down('md')]: {
    height: '80vh',
    paddingTop: '1rem'
  }
}));

const PrincipleImageContainerTwo = styled('div')(({ theme }) => ({
  width: '30%',
  height: '20vh',
  overflow: 'hidden',
  padding: '2rem',
  marginLeft: '3rem',
  [theme.breakpoints.down('md')]: {
    width: '90%',
    height: '15vh',
    padding: '1rem',
    marginLeft: '0rem',
    marginTop: '2rem'
  }
}));

const ImageContainer = styled('img')(({ theme }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  paddingTop: '8rem',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginLeft: '7rem',
  marginBottom: '4rem',
  [theme.breakpoints.down('md')]: {
    padding: '1rem',
    marginLeft: '1rem',
    flexDirection: 'column',
    marginBottom: '2rem'
  }
}));

const BoxContainerTwo = styled(Box)(({ theme }) => ({
    paddingTop: '2rem',
    paddingBottom: '2rem',
    marginLeft: '7rem',
    [theme.breakpoints.down('md')]: {
      marginLeft: '2rem'
    }
}));

const IconContainer = styled('div')(({ theme }) => ({
    height: '2.5rem',
    width: '2.5rem',
    borderRadius: '50%',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      height: '2rem',
      width: '2rem'
    }
}));

function Footer() {
  return (
    <div>
      <PrincipleImageContainer>
        <PrincipleImageContainerTwo>
          <ImageContainer src={Logo} alt="logo" />
        </PrincipleImageContainerTwo>

        <BoxContainer>
          <Stack>
            <Typography variant="h5" color="white">Let's Do Business</Typography>
            <Typography variant="body1" color="white">Nairobi, Kenya</Typography>
            <Typography variant="body1" color="white">Tel: +254 726970219</Typography>
            <Typography variant="body1" color="white">info@largescopedesigns.co.ke</Typography>
          </Stack>
          <Stack marginTop={{xs: 4, lg: 0}}>
            <Typography variant="h5" color="white">Business Hours</Typography>
            <Typography variant="body1" color="white">Monday - Friday: 9a.m - 6p.m</Typography>
            <Typography variant="body1" color="white">Saturday: 9a.m - 12nn</Typography>
          </Stack>
          <Stack></Stack>
          <Stack></Stack>
        </BoxContainer>
        <Divider sx={{ borderBottomWidth: '0.6rem', background: 'white' }}></Divider>
          <BoxContainerTwo>
              <Stack direction='row' alignItems='center'>
                <Typography variant="h5" sx={{fontWeight: 'bold'}} color="white">Get Socials</Typography>
                <Stack direction='row' marginLeft={2} gap={2}>
                  <IconContainer><ImFacebook style={{ color: Colors.orangeShade, fontSize: '1.5rem' }} /></IconContainer>
                  <IconContainer><InstagramIcon style={{ color: Colors.orangeShade, fontSize: '1.5rem' }} /></IconContainer>
                  <IconContainer><XIcon style={{color: Colors.orangeShade}}/></IconContainer>
                </Stack>
              </Stack>
          </BoxContainerTwo>
        <Divider sx={{ borderBottomWidth: '0.6rem', background: 'white' }}></Divider>
      </PrincipleImageContainer>
    </div>
  )
}

export default Footer