import React from 'react'
import { styled, Typography, Button } from '@mui/material';
import ExecutiveImage from '../assets/executive.png'
import { Colors } from '../theme/color';

const PrincipleImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
}));

const ImageContainer = styled('img')(({ theme }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
}));

const DarkOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(0, 0, 0, 0.0)',
  pointerEvents: 'none',
}));

const CenterContent = styled('div')(({theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: '#fff',
  zIndex: 1,
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '90%'
  }
}));

const Heading = styled(Typography)(({theme}) => ({
  fontSize: '4rem',
  fontWeight: 'bold',
  color: '#282A29',
  marginBottom: '1rem',
  [theme.breakpoints.down('md')] : {
    fontSize: '2rem'
  }
}));

const ActionButton = styled(Button)(({theme}) => ({
  border: '4px solid #FAEFE0',
  backgroundColor: Colors.orangeShade,
  color: '#FAEFE0',
  padding: '1rem',
  width: '40%',
  borderRadius: '0',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#D04C00'
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
  }
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: '1.4rem',
  color: '#73737D',
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem'
  }
}));

function Insights() {
  return (
    <div>
      <PrincipleImageContainer>
        <ImageContainer src={ExecutiveImage} alt="" />
        <DarkOverlay />
        <CenterContent>
          <Heading variant="h2">Our Executive Clientele</Heading>
          <DescriptionText variant="body1" color="#73737D" marginTop={2} marginBottom={2}>
            NCIC - National Cohesion and Inte- gration Commission. Three N international limit
            Eggen joinex limited - (National banks). Chartered engineering- (KCB banks) A-z studios
            limited. Branch international limited . Phatisa kenya . Twiga foods limited . Karen hospital
            (heart clinic). Global corporate operations one acre fund. Wasaani Restaurant (National
            the- atre)
          </DescriptionText>
          <ActionButton variant="contained">JOIN THE FAMILY</ActionButton>
        </CenterContent>
      </PrincipleImageContainer>
    </div>
  )
}

export default Insights