import React from 'react';
import HomeImage from '../assets/home.png';
import { styled, Typography, Button } from '@mui/material';
import About from './About';
import Services from './Services';
import Footer from '../components/Footer';
import Insights from './Insights';

const PrincipleImageContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '100vh',
  overflow: 'hidden',
}));

const ImageContainer = styled('img')(({ theme }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
}));

const DarkOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: 'rgba(0, 0, 0, 0.5)', // Adjust the alpha value for darkness
  pointerEvents: 'none',
}));

const CenterContent = styled('div')(({theme}) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  color: '#fff',
  zIndex: 1,
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '90%'
  }
}));

const Heading = styled(Typography)(({theme}) => ({
  fontSize: '5rem',
  fontWeight: 'bold',
  marginBottom: '1rem',
  [theme.breakpoints.down('md')] : {
    fontSize: '2rem'
  }
}));

const ActionButton = styled(Button)(({theme}) => ({
  border: '4px solid white',
  backgroundColor: 'transparent',
  color: '#fff',
  padding: '1rem',
  width: '40%',
  borderRadius: '0',
  fontSize: '1.5rem',
  '&:hover': {
    backgroundColor: '#fff',
    color: 'black',
  },
  [theme.breakpoints.down('md')]: {
    width: '80%',
    fontSize: '1rem'
  }
}));

function HomePage() {

  return (
    <>
      <PrincipleImageContainer>
        <ImageContainer src={HomeImage} alt="" />
        <DarkOverlay />
        <CenterContent>
          <Heading variant="h2">Offering Stunning Innovations & Concepts</Heading>
          <ActionButton variant="contained">WHAT WE OFFER</ActionButton>
        </CenterContent>
      </PrincipleImageContainer>

      <About />
      <Services />
      <Insights />
      <Footer />
    </>
  );
}

export default HomePage;
