import React from 'react'
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import StoryImage from '../assets/story.jpg';
import { Colors } from '../theme/color';

const PrincipleImageContainer = styled('div')(({ theme }) => ({
    width: '100%',
    minHeight: '60vh',
    overflow: 'hidden',
    background: Colors.orangeShade,
    // background: 'red',
    [theme.breakpoints.down('md')]: {
      height: 'auto'
    }
  }));
  
  const PrincipleImageContainerTwo = styled('div')(({ theme }) => ({
    width: '100%',
    height: '65vh',
    overflow: 'hidden',
    // background: 'orange',
    // marginTop: '3rem',
    [theme.breakpoints.down]: {
      // marginTop: '1rem',
    //   height: '30vh'
    }
  }));
  
  const HeaderContainer = styled(Box)(({ theme }) => ({
    marginBottom: '8rem',
    marginLeft: '7%',
    marginTop: '1rem',
    [theme.breakpoints.down('md')]: {
        marginBottom: '1rem',
        marginLeft: '1%'
    }
  }));
  
  const ImageContainer = styled('img')(({ theme }) => ({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    [theme.breakpoints.down('md')]: {
      height: '70%',
      marginTop: '2rem'
    }
  }));

  const ActionButton = styled(Button)(({theme}) => ({
    border: '4px solid #FAEFE0',
    backgroundColor: 'transparent',
    color: '#FAEFE0',
    padding: '1rem',
    width: '60%',
    marginTop: '2rem',
    fontSize: '1.3rem',
    borderRadius: '0',
    '&:hover': {
      backgroundColor: '#fff',
      color: 'black',
      marginLeft: '0'
    },
    [theme.breakpoints.down('md')] :{
      fontSize: '1rem',
     }
  }));

  const BoxContainer = styled(Box)(({ theme }) => ({
    height: '80vh',
    // background: 'red',
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    margin: '5rem',
    marginTop: '-5rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      margin: '1rem',
      justifyContent: 'center',
      height: '50vh',
      marginTop: '15rem'
    }
  }));

  const OverallBox = styled(Box)(({ theme }) => ({
    margin: '6rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      margin: '0rem',
      paddingBottom: '10rem'
    }
  }));

  const HeadingText = styled(Typography)(({ theme }) => ({
    fontSize: '4rem',
    color: '#FAEFE0',
    [theme.breakpoints.down('md')]: {
      fontSize: '2rem'
    }
  }));
  
  const DescriptionText = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    color: '#FAEFE0',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem'
    }
  }));

function Story() {
  return (
    <div>
        <PrincipleImageContainer>
          <OverallBox>
            <BoxContainer>
            <Stack flex={1}>
                <PrincipleImageContainerTwo>
                <ImageContainer src={StoryImage} alt="" />
                </PrincipleImageContainerTwo>
            </Stack>
            <Stack flex={1} sx={{background: 'transparent'}} >
                <HeaderContainer>   
                    <HeadingText variant="h4" color="white" sx={{fontWeight: 'bold'}} marginTop={{xs: 1, lg: 1}}>Our Story</HeadingText>
                </HeaderContainer>
                <Box marginLeft={{xs: 0, lg: 5}}>
                    <DescriptionText variant="body1" color="white" width={{xs: '100%', lg: '90%'}}>Largescope Designs is a Design and Interior company in kenya that incorporates innovative products and concepts that are simply stunning and often outshine the industry standards.</DescriptionText>
                    <ActionButton>INQUIRE</ActionButton>
                </Box>
            </Stack>
            </BoxContainer>
            </OverallBox>
        </PrincipleImageContainer>
    </div>
  )
}

export default Story