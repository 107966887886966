import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';


const ThemeProvider = ({ children }) => {

    const fontFamily = '"DM Sans", sans-serif';

    const theme = createTheme({
        palette: {
          primary: {
            main: '#059ECC',
          },
          secondary: {
            main: '#FAEFE0'
          }
        },
        typography: {
            fontFamily
        },
    });
  return (
    <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
  )
}

export default ThemeProvider