import TopMenu from "./components/TopMenu";
import ThemeProvider from './theme/theme';
import Router from "./routes/index";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
    <ThemeProvider>
      <ScrollToTop />
      <TopMenu />
      <Router />
    </ThemeProvider>
    </>
  );
}

export default App;
