import { Stack, styled, Typography } from '@mui/material';
import React from 'react'

const PrincipleImageContainer = styled('div')(({ theme }) => ({
    width: '90%',
    height: '80vh',
    overflow: 'hidden',
    border: '4px solid #D04C00',
    margin: '1rem',
    [theme.breakpoints.down('md')]: {
      height: '78vh'
    }
  }));

  const PrincipleImageContainerTwo = styled('div')(({ theme }) => ({
    width: '100%',
    height: '30vh',
    overflow: 'hidden',
    // border: '2px solid red'
  }));
  
  
  const ImageContainer = styled('img')(({ theme }) => ({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  }));

  const HeadingText = styled(Typography)(({ theme }) => ({
    fontSize: '1.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.2rem'
    }
  }));
  

function Card({Heading, Item, Description, imageUrl}) {
  return (
    <div>
        <PrincipleImageContainer>
           <Stack>
            <Stack >
                <PrincipleImageContainerTwo>
                    <ImageContainer src={imageUrl} alt="" />
                </PrincipleImageContainerTwo>
                <HeadingText variant="h5" color="initial" paddingLeft={4} paddingTop={2} paddingBottom={2}>{Heading}</HeadingText>
            </Stack>
            <Stack sx={{borderTop: '4px solid #D04C00', padding: '2rem'}}>
                <Typography variant="body1" color="initial" marginBottom={2} sx={{fontWeight: 'bold'}}>{Item}</Typography>
                <Typography variant="body1" color="initial">{Description}</Typography>
            </Stack>
           </Stack>
        </PrincipleImageContainer>
    </div>
  )
}

export default Card