import { Box, Button, Grid, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import AboutImage from '../assets/about.jpg';
import AboutCard from '../components/AboutCard';
import Story from './Story';
import { Colors } from '../theme/color';

const VISION = [
  {heading: 'The Mission', description: "Largescope Designs aims to provide professional service to its clients' satisfaction by providing service excellence"},
  {heading: 'Vision ', description:(<>Delivering unique and innovative designs down to the finest detail! </>)},
  {heading: 'Our Values ', description: 'Passion for creativity Customer driven Unique & innovative designs Seamless Service Delivery Staff Well Being '},
];

const PrincipleImageContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  overflow: 'hidden',
  background: Colors.orangeShade,
  [theme.breakpoints.down('md')]: {
    height: 'auto'
  }
}));

const PrincipleImageContainerTwo = styled('div')(({ theme }) => ({
  width: '100%',
  height: '60vh',
  overflow: 'hidden',
  // background: 'orange',
  // marginTop: '3rem',
  [theme.breakpoints.down]: {
    // marginTop: '1rem',
    // height: '30vh'
  }
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  marginBottom: '2rem',
  marginLeft: '6%',
  marginTop: '8rem',
  [theme.breakpoints.down('md')]: {
    marginTop: '2rem'
  }
}));

const ImageContainer = styled('img')(({ theme }) => ({
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  [theme.breakpoints.down('md')]: {
    height: '50%'
  }
}));

const ActionButton = styled(Button)(({theme}) => ({
  border: '4px solid #FAEFE0',
  backgroundColor: 'transparent',
  color: '#FAEFE0',
  padding: '1rem',
  width: '60%',
  marginTop: '2rem',
  fontSize: '1.3rem',
  borderRadius: '0',
  '&:hover': {
    backgroundColor: '#fff',
    color: 'black',
    marginLeft: '0'
  },
  [theme.breakpoints.down('md')] :{
    fontSize: '1rem',
   }
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  height: '40vh',
  // background: Colors.orangeShade,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '5rem',
  marginTop: '10rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    margin: '1rem',
    justifyContent: 'center',
    height: '30vh',
    marginTop: '15rem'
  }
}));

const OverallBox = styled(Box)(({ theme }) => ({
  margin: '6rem',
  [theme.breakpoints.down('md')]: {
    margin: '0rem'
  }
}));

const HeadingText = styled(Typography)(({ theme }) => ({
  fontSize: '4rem',
  color: '#FAEFE0',
  [theme.breakpoints.down('md')]: {
    fontSize: '2rem'
  }
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  color: '#FAEFE0',
  fontWeight: 'bold',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.2rem'
  }
}));


function About({id}) {
  return (
    <div id='about'>
      <PrincipleImageContainer>
        <OverallBox>
        <HeaderContainer>
          <HeadingText variant="h4" color="white" sx={{fontWeight: 'bold'}} marginTop={{xs: 1, lg: 5}}>Briefly <br /> About Us</HeadingText>
        </HeaderContainer>
      
        <BoxContainer>
          <Stack flex={1}>
            <PrincipleImageContainerTwo>
              <ImageContainer src={AboutImage} alt="" />
            </PrincipleImageContainerTwo>
          </Stack>
          <Stack flex={1} sx={{background: 'transparent'}} >
            <Box marginLeft={{xs: 0, lg: 20}} marginBottom={{xs: 4, lg: 0}}>
              <DescriptionText variant="body1" color="white" width={{xs: '100%', lg: '90%'}}>We offer extensive expertise and experience across all design and Interiors fields</DescriptionText>
              <ActionButton>LEARN MORE</ActionButton>
            </Box>
          </Stack>
        </BoxContainer>
        </OverallBox>
      {/* <BoxContainerTwo> */}
        <Grid container spacing={{xs: 2, lg: 1}} justifyContent='center'>
          {VISION.map((principle, index) => {
            return (
              <Grid item key={index} xs={12} md={6} lg={3}>
                <AboutCard Heading={principle.heading} Description={principle.description} />
              </Grid>
            )
          })}
        </Grid>
      {/* </BoxContainerTwo> */}
      </PrincipleImageContainer>
      
      <Story />

    </div>
  )
}

export default About