import React from 'react';
import { useRoutes } from 'react-router-dom';
import HomePage from '../screens/HomePage';
import About from '../screens/About';
import Services from '../screens/Services';
import Insights from '../screens/Insights';
import Contacts from '../screens/Contacts';
import Footer from '../components/Footer';


const Router = () => {
    const router = useRoutes([
        { path: '/', element: <HomePage />},
        { path: '/about', element: <About />},
        { path: '/services', element: <Services />},
        { path: '/insights', element: <Insights />},
        { path: '/contacts', element: <Footer />},
    ]);

    return router;
}

export default Router;